import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  typography: {
    fontFamily: "Raleway, Arial",
  },

  card: {
    borderRadius: 20,
  },
}));
